import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";
import FilterButton from "shared-components/Button/FilterButton";
import Tag from 'shared-components/Chip/Tag';
import CustomIconButton from "shared-components/Button/IconButton";
import Switch from "shared-components/Switch/Switch";
import DateRangePicker from 'shared-components/DatePicker/DateRangePicker';

import { useInterval } from 'common/utils';
import { renderValue, filterParam } from 'common/functions';
import { useRouteCanWrite } from "hooks";
import { rowsPerTable } from "config";
import { setSearchText } from "store/general";
import { setValues } from "../store/germination";
import { qualityControlGerminationTableHead } from "enums/UserPortal/TableHeaders";
import { GetAllGerminationReports, DeleteGerminationReport, GetProductList } from "services/UserPortal/QualityControlService";

import report from "assets/icons/orange/view-report.svg";
import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/qualityControlStyle.js";

const useStyles = makeStyles(styles);

export default function QualityControlGermination() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const isFirstUpdate = React.useRef(true);
  const canWrite = useRouteCanWrite();
  const workOrderList = useSelector(store => store.common.workOrderList);
  const isDisabled = useSelector(store => store.navbar.isDisabled);
  const filter = useSelector(store => store.user.qualityControl.germination.filter);
  const productList = useSelector(store => store.user.qualityControl.germination.filterProductList);
  const totalCount = useSelector(store => store.user.qualityControl.germination.totalCount);
  const result = useSelector(store => store.user.qualityControl.germination.result);
  const [newFilter, setNewFilter] = React.useState({workOrderId: null, productId: null, isPastRecord: false, page: 0});
  const [id, setId] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleOnChange_date = (value) => {
    if (value) {
      dispatch(setValues({filter: {...filter, startDate: moment(value.startDate).format("YYYY-MM-DD"), endDate: moment(value.endDate).format("YYYY-MM-DD"), page: 0}}));
    }
  };

  const handleButtonClick_deleteTag = (key) => {
    if (key === "workOrderId") {
      dispatch(setValues({filter: {...filter, [key]: null, productId: null, page: 0}}));
    } else {
      dispatch(setValues({filter: {...filter, [key]: null, page: 0}}));
    }
  };

  const handleModal = (id) => {
    setId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteGerminationReport(id))
    .then(() =>{
      resetState();
      getAllQualityControl(0);
    })
  }

  const resetState = () => {
    setId(null);
    dispatch(setValues({filter: {...filter, page: 0}}));
    setOpenModal(!openModal);
  }

  const getAllQualityControl = (stopLoading) => {
    dispatch(GetAllGerminationReports({...filter, stopLoading}));
  }

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      dispatch(GetProductList({isPastRecord : filter.isPastRecord}));
      getAllQualityControl();
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    }
  },[isDisabled]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
  },[]);
  
  useInterval(() => {
    getAllQualityControl(true);
  });

  return (
    <React.Fragment>
      <Card 
        title = "Quality Control Entries - Germination Reports"
        subheader={
          <React.Fragment>
            <Typography>Choose the Work Order to know the Germination Rate for each batch</Typography>
            { Object.keys(filter).map((key) => {
              if (filter[key] && key !== "isPastRecord" && key.includes("Date") && key !== "page") {
                let label = filter[key];
                if (key === "workOrderId") {
                  const workOrder = workOrderList.find(({id}) => id == filter[key]);
                  label = workOrder && workOrder.workOrderNo;
                }
                if (key === "productId") {
                  const product = productList.find(({id}) => id == filter[key]);
                  label = product && (product.productName + "(" + product.productRefNo + ")");
                }
                return (
                  <Tag
                    key={key}
                    variant="outlined"
                    tabIndex={-1}
                    label={label}
                    className={classes.tag}
                    onDelete={() => handleButtonClick_deleteTag(key)}
                  />
                )
              }
            })}
          </React.Fragment>
        }
        action={
          <React.Fragment>
            <div className={classes.filterSwitch}>
              <Typography className={classes.switchText}>Past Records: </Typography>
              <Switch 
                checked={String(filter.isPastRecord) === "true"}
                onChange={() => dispatch(setValues({filter: {...filter, isPastRecord: !filter.isPastRecord, page: 0}}))}
              />
            </div>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the germination list
                  <Select
                    className={classes.filterDropdown}
                    name="workOrderId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a work order"
                    value={renderValue(newFilter.workOrderId)}
                  >
                    {workOrderList && workOrderList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.workOrderNo}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="productId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a product"
                    value={renderValue(newFilter.productId)}
                  >
                    {productList && productList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.productName} ({item.productRefNo})</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
            <DateRangePicker
              clearable
              placeholder="Transplant date"
              className={classes.cardActionDate}
              value={{startDate: filter.startDate, endDate: filter.endDate}}
              onChange={(e) => handleOnChange_date(e)}
              onClear={()=>dispatch(setValues({filter: {...filter, startDate: null, endDate: null, page: 0}}))}
            />
          </React.Fragment>
        }
      >
        <div className={classes.table}>
          <Table 
            className={classes.mainTable}
            header={qualityControlGerminationTableHead}
          >
            { result.map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item.workOrderNumber}</TableCell> 
                  <TableCell>{item.productName}</TableCell> 
                  <TableCell>{moment(item.transplantDate).format("DD/MM/YYYY")}</TableCell> 
                  <TableCell>{moment(item.submittedDate).format("DD/MM/YYYY")}</TableCell> 
                  <TableCell>{item.submittedBy}</TableCell> 
                  <TableCell align="right">
                    <Tooltip title="Report" placement="top">
                      <IconButton 
                        onClick={() => history.push({pathname: "/user/quality-control/germination/report/"+item.id, state: {isPastRecord: filter.isPastRecord}})}
                      >
                        <img className={classes.icon} src={report} alt="report" />
                      </IconButton>
                    </Tooltip>
                    {canWrite && !filter.isPastRecord &&
                      <React.Fragment>
                        <Link to={{pathname: "/user/quality-control/germination/"+item.id}}>
                          <CustomIconButton type="edit" />
                        </Link>
                        <CustomIconButton
                          type="delete" 
                          onClick={() => handleModal(item.id)}
                        />
                      </React.Fragment>
                    }
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
      </Card>
      <Modal
        open={openModal}
        onClose={() => handleModal(null)}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this item? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
