// ASRS changed to VIFS
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";

import { useRouteCanWrite } from "hooks";
import { useInterval } from 'common/utils';
import { setSuccess, setSearchText } from "store/general";
import { filterParam, filterUrl } from 'common/functions';
import { stationTableHead } from "enums/UserPortal/TableHeaders";
import { reset, setValues } from "../store/station";
import { GetAllASRSStationStatusList, StartASRSStation } from "services/UserPortal/ASRSService";

import styles from "assets/jss/components/UserPortal/asrsStyle.js";

const useStyles = makeStyles(styles);

export default function Station() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const filter = useSelector(store => store.user.asrs.station.filter);
  const totalCount = useSelector(store => store.user.asrs.station.totalCount);
  const result = useSelector(store => store.user.asrs.station.result);
  const [newFilter, ] = React.useState({page: 0});

  const count = Math.ceil(totalCount / 10);

  const getAllStation = (stopLoading) => {
    dispatch(GetAllASRSStationStatusList({...filter, stopLoading}));
  }

  const handleButtonClick = (action) => {
    dispatch(StartASRSStation({actionType: action}))
    .then(({error}) => {
      if (!error) {
        dispatch(setSuccess("Available stations are turned " + (action ==='S' ? "on" : "off")));
      }
      getAllStation();
    });
  }

  React.useEffect(() => {
    window.history.pushState({}, '', filterUrl(filter));
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getAllStation();
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
    dispatch(setSearchText(""));
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  useInterval(() => {
    getAllStation(true);
  });

  return (
    <React.Fragment>
      <Card 
        title="VIFS - Stations List"
        action={canWrite &&
          <React.Fragment>
            <Button 
              className={classes.buttonSecondary}
              onClick={() => handleButtonClick('S')}
            >
              Turn On
            </Button>
            <Button 
              className={clsx(classes.buttonSecondary, classes.cardActionDate)}
              onClick={() => handleButtonClick('E')}
            >
              Turn Off
            </Button>
          </React.Fragment>
        }
      >
        <div className={classes.table}>
          <Table
            header={stationTableHead}
          >
            { result.map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item.stationID}</TableCell>
                  <TableCell>{item.functionName}</TableCell>
                  <TableCell>{item.status}</TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
      </Card>
    </React.Fragment>
  );
}
