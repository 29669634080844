import {
  cardRadius,
  border,
  icon_24,
  icon_64,
  button,
  buttonSecondary,
  primaryColor,
  secondaryColor,
  grayColor,
  whiteColor,
} from "assets/jss/generaliseStyle.js";

import {
  orange,
  table,
  subTable,
  cardPaper,
  cardTitle,
  icon,
  result,
  collapsedIcon,
  rotate,
  highlight,
  link,
} from "assets/jss/components/commonStyle.js";
import { backgroundColor } from "assets/jss/generaliseStyle";

const amrStyle = {
  cardRadius,
  border,
  button,
  buttonSecondary,
  icon_24,
  icon_64,
  orange,
  table,
  subTable,
  cardPaper,
  cardTitle,
  icon,
  link,

  // Search amr
  result,
  collapsedIcon,
  rotate,
  highlight,

  // Display amr
  mainTable: {
    '& thead > tr > th:nth-child(1)': {
      width: "9vw",
      minWidth: "9vw"
    },
  },
  progressBar: {
    width: "6vw",
    height: "0.521vw",
    borderRadius: "0.260vw",
    border: "0.052vw solid "+grayColor[8],
    backgroundColor: grayColor[6],
    marginRight: "0.521vw"
  },
  progress1: {
    backgroundColor: "#e05e4c"
  },
  progress2: {
    backgroundColor: "#d56739"
  },
  progress3: {
    backgroundColor: "#c77128"
  },
  progress4: {
    backgroundColor: "#b67a1a"
  },
  progress5: {
    backgroundColor: "#a38111"
  },
  progress6: {
    backgroundColor: "#908813"
  },
  progress7: {
    backgroundColor: "#7a8d1f"
  },
  progress8: {
    backgroundColor: "#63912f"
  },
  progress9: {
    backgroundColor: "#489440"
  },
  progress10: {
    backgroundColor: "#219653"
  },
  cardHeader: {
    padding: "1.250vw !important",
    marginBottom: 0,
  },
  cardContent: {
    padding: "1.250vw !important",
  },
  flexComponent: {
    display: "flex",
    alignItems: "center",
    overflow: "overlay",
    '& :not(:last-child)': {
      marginRight: "1.250vw"
    }
  },
  bottomSpace: {
    marginBottom: "1.250vw"
  },
  clickable: {
    cursor: "pointer"
  },
  clear: {
    ...orange,
    marginTop: "0.25vw",
    cursor: "pointer",
    textAlign: "right"
  },
  list: {
    height: "12vw",
    marginTop: "0.75vw",
    overflow: "overlay",
  },
  listItem: {
    display: "flex",
    marginLeft: "-1.042vw",
    "& :first-child": {
      marginRight: "1.042vw"
    }
  },
  gridContainer: {
    alignItems: "center"
  },
  arrayButton: {
    cardRadius,
    border,
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    width: "3vw",
    height: "3vw",
    "&:hover": {
      backgroundColor: secondaryColor[0]+"20"
    }
  },
  scaleContainer: {
    display: "flex",
    justifyContent: "end"
  },
  filterContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  filterFields: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1.25vw",
  },
  filterField: {
    display: "flex",
    alignItems: "center",
    marginRight: "3vw",
    "& p": {
      whiteSpace: "nowrap",
      marginRight: "1.25vw",
    }
  },
  filterSelect: {
    width: "8vw",
  },
  cancelButton: {
    ...buttonSecondary,
    paddingTop: "0.250vw",
    paddingBottom: "0.250vw"
  },
  mapDiv: {
    position: "relative"
  },
  canvas: {
    position: "absolute",
    width: "-webkit-fill-available",
    height: "-webkit-fill-available",
    zIndex: 99
  },
  markerDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    transform: "translate(-50%, -50%)",
  },
  marker: {
    ...icon_24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: secondaryColor[0],
    border: "0.208vw solid " + secondaryColor[0],
    borderRadius: "50%",
    color: whiteColor,
  },
  markerText: {
    fontSize: "0.750vw",
  },

  // Create Task
  formField: {
    display: "flex",
    alignItems: "center",
    "& p": {
      textAlign: "right",
      minWidth: "10vw",
      marginRight: "1.25vw"
    }
  },
  radioLabel: {
    textAlign: "left !important",
    minWidth: "fit-content !important",
  },
  locationButton: {
    border,
    color: primaryColor[3],
    marginRight: "1.250vw",
    marginTop: "0.750vw",
    minWidth: "4vw",
  },
  selectedButton: {
    // borderColor: secondaryColor[0],
    color: secondaryColor[0],
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "end"
  },
  error: {
    color: "#FF0000",
    fontSize: "0.729vw",
  },

  // Inventory Count
  zoneTitle: {
    fontWeight: 500,
    fontSize: "1vw",
  },
  palletteContainer: {
    ...cardRadius,
    border,
    backgroundColor,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: "1.979vw"
  },
  palletteCount: {
    fontWeight: 600,
    fontSize: "2.500vw",
    marginBottom: "1.250vw"
  }
}

export default amrStyle;