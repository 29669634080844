import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
// core components
import Select from "shared-components/Select/Select";
import TextField from "shared-components/TextField/TextField";
import DateTimePicker from 'shared-components/DatePicker/DateTimePicker';

import ProductList from "./ProductList";
import { setDirty } from "store/general";
import { renderValue } from "common/functions";
import { updateRequest, resetOrder } from "../store/management";
import { GetCustomerList, GetPONumberFromERP } from "services/UserPortal/CustomerOrderService";

import styles from "assets/jss/components/UserPortal/customerOrderStyle.js";

const useStyles = makeStyles(styles);

export default function OneOffOrder(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const order = useSelector(store => store.user.customerOrder.management.customerOrder);
  const customerList = useSelector(store => store.user.customerOrder.management.customerList);
  // const poNumberList = useSelector(store => store.user.customerOrder.management.poNumberList);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleOnChange_select = (e) => {
    dispatch(updateRequest({[e.target.name] : e.target.value}));
  };

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_date = (value) => {
    if(value && value.isValid() && value != "Invalid Date") {
      dispatch(updateRequest({deliveryDate : moment(value).format("YYYY-MM-DD")}));
    } else {
      dispatch(updateRequest({deliveryDate : value}));
    }
  };

  const validateFields = React.useCallback(() => {
    if (!order.customerId) {
      setErrorMsg({field: "customerId", msg: "Please select a customer"});
      return false;
    }
    if (!order.purchaseOrderNumber) {
      setErrorMsg({field: "purchaseOrderNumber", msg: "Please enter a PO number"});
      return false;
    }
    if (!order.deliveryDate) {
      setErrorMsg({field: "deliveryDate", msg: "Please enter a date"});
      return false;
    }
    if (!moment(order.deliveryDate).isValid()) {
      setErrorMsg({field: "deliveryDate", msg: "Invalid Date"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }, [order]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setDirty(true));
    dispatch(GetCustomerList());
    dispatch(GetPONumberFromERP());
    // componentDidUnmount
    return () => {
      dispatch(resetOrder());
    }
  },[]);

  return (
    <React.Fragment>
      <Typography className={classes.formTitle}>Customer Information</Typography>
      <Grid container spacing={4}>
        <Grid item xs={4} className={classes.grid}>
          <Typography className={classes.formLabel}>Customer Name:</Typography>
          <Select
            name="customerId"
            onChange={(e)=>handleOnChange_select(e)}
            placeholder="Select customer"
            value={renderValue(order.customerId)}
            errorMsg={errorMsg}
            disabled={props.isEdit}
          >
            {customerList && customerList.map((item, index) => {
              return <MenuItem key={index} value={item.id}>{item.customerName} ({item.customerRefNo})</MenuItem>;
            })} 
          </Select>
        </Grid>
        <Grid item xs={4} className={classes.grid}>
          <Typography className={classes.formLabel}>Customer PO:</Typography>
          <TextField
            id="purchaseOrderNumber"
            variant="outlined" 
            inputProps={{ maxLength: 20 }}
            placeholder="Enter purchase order"
            onChange={(e) => handleOnChange_text(e)}
            value={renderValue(order.purchaseOrderNumber)}
            errorMsg={errorMsg}
            disabled={props.isEdit}
          />
        </Grid>
        <Grid item xs={4} className={classes.grid}>
          <Typography className={classes.formLabel}>Delivery Date:</Typography>
          <DateTimePicker
            className={classes.datepicker}
            name="deliveryDate"
            value={order.deliveryDate}
            onChange={(e) => handleOnChange_date(e)}
            errorMsg={errorMsg}
          />
        </Grid>
      </Grid>
      <ProductList validateFields={validateFields} setIsEdit={props.setIsEdit} />
    </React.Fragment>
  );
}
