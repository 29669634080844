import { createAsyncThunk } from "@reduxjs/toolkit";

import {AMRMS} from "../index";

export const ExecuteGetAllMaps = createAsyncThunk(
  "amr/executeGetAllMaps", 
  async (_, {rejectWithValue}) => {
    try {
      const response = await AMRMS.get("/AMR/ExecuteGetAllMaps");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const ExecuteGetAllRobots = createAsyncThunk(
  "amr/executeGetAllRobots", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const params = {
      ...param,
    };
    try {
      const response = await AMRMS.get("/AMR/ExecuteGetAllRobots", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const ExecuteGetAllMission = createAsyncThunk(
  "amr/executeGetAllMission", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    var params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (Array.isArray(param[key])) {
        param[key].map((item) => params.append(key, item));
      } else if (param[key]) {
        params.append(key, param[key]);
      }
    })
    try {
      const response = await AMRMS.get("/AMR/ExecuteGetAllMission", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const GetUpcomingMissions = createAsyncThunk(
  "amr/getUpcomingMissions", 
  async (param, {rejectWithValue}) => {
    var params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (Array.isArray(param[key])) {
        param[key].map((item) => params.append(key, item));
      } else if (param[key]) {
        params.append(key, param[key]);
      }
    })
    try {
      const response = await AMRMS.get("/AMR/GetUpcomingMissions", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const ExecuteGetMapByName = createAsyncThunk(
  "amr/executeGetMapByName", 
  async (param, {rejectWithValue}) => {
    const params = {
      ...param
    };
    try {
      const response = await AMRMS.get("/AMR/ExecuteGetMapByName", {params, stopLoading: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const ExecuteGetMissionByUUID = createAsyncThunk(
  "amr/executeGetMissionByUUID", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    const params = {
      ...param
    };
    try {
      const response = await AMRMS.get("/AMR/ExecuteGetMissionByUUID", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const CreateOrUpdateMission  = createAsyncThunk(
  "amr/createOrUpdateMission", 
  async (param, {rejectWithValue}) => {
    const body = {...param};
    try {
      const response = await AMRMS.post("/AMR/CreateOrUpdateMission", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const ExecuteCancelMission  = createAsyncThunk(
  "amr/executeCancelMission", 
  async (id, {rejectWithValue}) => {
    const body = {
      mission_id: id,
      cmd: 2,
      sync: true,
      id: 1,
      result: 1
    };
    try {
      const response = await AMRMS.post("/AMR/ExecuteCancelMission", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const DeleteUpcomingMissions  = createAsyncThunk(
  "amr/deleteUpcomingMissions", 
  async (id, {rejectWithValue}) => {
    const params = {
      missionId: id,
    };
    try {
      const response = await AMRMS.post("/AMR/DeleteUpcomingMissions", null, {params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

// Inventory Count
export const GetInventoryCount = createAsyncThunk(
  "amr/getInventoryCount", 
  async ({stopLoading, ...param}, {rejectWithValue}) => {
    var params = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (Array.isArray(param[key])) {
        param[key].map((item) => params.append(key, item));
      } else if (param[key]) {
        params.append(key, param[key]);
      }
    })
    try {
      const response = await AMRMS.get("/AMR/GetInventoryCount", {params, stopLoading});
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);