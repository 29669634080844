/* eslint-disable no-unused-vars */ // TO REMOVE
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui/material
// core components
import Card from "shared-components/Card/Card";
import Tabs from "shared-components/Tabs/Tabs";

import { setSearchText } from "store/general";

import TaskList from "./TaskList";
import QuickAction from "./QuickAction";
import TaskCreation from "./TaskCreation";
import PastTaskRepository from "./PastTaskRepository";
import FutureTask from "./FutureTask";

export default function PalletManagement() {
  const dispatch = useDispatch();
  const isFirstUpdate = React.useRef(true);
  const isDisabled = useSelector(store => store.navbar.isDisabled);
  const [tab, setTab] = React.useState(0);

  const handleOnChange_tab = (e, value) => {
    setTab(value);
  }
  
  // componentDidMount
  React.useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    }
  },[isDisabled]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
  },[]);

  const renderComponent = () => {
    switch (tab) {
      case 0:
        return <TaskList />
      case 1:
        return <TaskCreation />
      case 2:
        return <FutureTask />
      // case 1:
      //   return <QuickAction />
      // case 2:
      //   return <TaskCreation />
      // case 3:
      //   return <PastTaskRepository />
      // case 4:
      //   return <FutureTask />
    }
  }

  return (
    <React.Fragment>
      <Card 
        title="AMR Management - Pallet Management"
        subheader={
          <Tabs 
            tabs={["Level 1", "Level 3", "Level 4"]}  //["Task List", "Quick Action", "Task Creation", "Past Tasks Repository", "Future Tasks"]
            value={tab} 
            onChange={handleOnChange_tab}
          />
        }
      >
        {renderComponent()}
      </Card>
    </React.Fragment>
  );
}
