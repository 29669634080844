import { createSlice } from "@reduxjs/toolkit";

import { GetInventoryCount } from "services/UserPortal/AMRService";

// initial state
const initialState = {
  result: [],
};

const amrInventoryCount = createSlice({
  name: "amrInventoryCount",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.batch[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetInventoryCount.fulfilled]: (state, action) => {
      state.result = action.payload.result;
    },
  },
});

// export actions
export const { setValues, updateRequest, updateExcessOrderRequest, reset } = amrInventoryCount.actions;

// export the reducer
export default amrInventoryCount.reducer;