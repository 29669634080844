import { combineReducers } from "redux";
import overview from "./overview";
import adhoc from "./adhoc";
import inventoryCount from "./inventoryCount";

const AMRReducer = combineReducers({
  overview,
  adhoc,
  inventoryCount,
});

export default AMRReducer;