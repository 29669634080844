// Farm Capacity
// Cages Categories edit page
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Card from "shared-components/Card/Card";
import Accordion from "shared-components/Accordion/Accordion";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";
import Checkbox from "shared-components/Checkbox/Checkbox";
import Modal from "shared-components/Modal/Modal";
import CustomIconButton from "shared-components/Button/IconButton";

import { formatNumbers } from "common/functions";
import { setDirty } from "store/general";
import { setValues, updateRequest, updateCategoryRequest, resetCategory, reset } from "./store/farmCapacity";
import { GetCapacityCategoryByFarmId, GetAllSoftzone, GetAllLocationBySoftzone, GetAllProduct, GetAllProductProcess, CreateOrUpdateCapacityCategoryList } from "services/AdminPortal/CapacityService";

import BankExplanation from "components/AdminPortal/util/BankExplanation";
import arrow from "assets/icons/orange/droplist-arrow.svg";
import helper from "assets/icons/black/helper.svg";
import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/capacityStyle.js";

const useStyles = makeStyles(styles);
export default function CageCategory(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const softzoneList = useSelector(store => store.admin.capacity.farmCapacity.softzoneList);
  const locationList = useSelector(store => store.admin.capacity.farmCapacity.locationList);
  const category = useSelector(store => store.admin.capacity.farmCapacity.category);
  const categories = useSelector(store => store.admin.capacity.farmCapacity.categories);
  const product = useSelector(store => store.admin.capacity.farmCapacity.product);
  const process = useSelector(store => store.admin.capacity.farmCapacity.process);
  const [showExplanation, setShowExplanation] = React.useState(false);
  const [categoryOpened, setCategoryOpened] = React.useState(null);
  const [selectedByOthers, setSelectedByOthers] = React.useState([]);
  const [softzoneOpened, setSoftzoneOpened] = React.useState(null);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isAddNew, setIsAddNew] = React.useState(false);
  const [categoryIndex, setCategoryIndex] = React.useState(null);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const userDetail = JSON.parse(localStorage.getItem("userDetail"));

  const removeAllLocations = (payload, value) => {
    _.map(payload[categoryOpened].capacities, (capacity) => { // remove all locations
      if (capacity.asrsSoftzoneId === Number(value)) {
        return Object.assign(capacity, {isDeleted: true});
      }
      return capacity;
    })
  }

  const handleButtonClick_checkbox = (e, softzoneValue) => {
    let payload = _.cloneDeep(categories);
    let value = e.target.value;
    switch (e.target.id) {
      case "softzone": {
        if (!e.target.checked) {  // checked previously, remove
          payload[categoryOpened].selectedSoftzones = _.filter(payload[categoryOpened].selectedSoftzones, (softzone) => softzone != value); // remove all location in softzone
          removeAllLocations(payload, value);
          _.remove(payload[categoryOpened].selectedSoftzones, (n) => n === Number(value));  // remove softzone
          dispatch(updateRequest({categories : payload}));
        } else {
          const selectedSoftzones = payload[categoryOpened].selectedSoftzones || [];
          payload[categoryOpened].selectedSoftzones = _.unionBy(selectedSoftzones, [Number(value)]);
          dispatch(GetAllLocationBySoftzone({softzoneId: value}))
          .then((response) => {
            const list = response.payload.result[0].locations;
            payload = updateLocationSelectionFromSoftzone(_.cloneDeep(payload), list, value);
            dispatch(updateRequest({categories : payload}));
          });
        }
        break;
      }
      case "location": {
        updateLocationSelection(payload, value, softzoneValue);
        dispatch(updateRequest({categories : payload}));
        break;
      }
    }
  }

  const updateLocationSelection = (payload, value, softzoneValue) => {
    const index = _.findIndex(categories[categoryOpened].capacities, (n) => n.asrsLocationId === Number(value));
    if (index !== -1) {  // alr exists
      let capacity = payload[categoryOpened].capacities[index];
      payload[categoryOpened].capacities[index] = Object.assign(capacity, {isDeleted: !capacity.isDeleted});
    } else {  // not in list, add new
      const capacity = {asrsSoftzoneId: Number(softzoneValue), asrsLocationId: Number(value)};
      payload[categoryOpened].capacities = [...payload[categoryOpened].capacities, capacity];
    }
  }

  const updateLocationSelectionFromSoftzone = (payload, value, softzoneValue) => {
    for (let i = 0; i < value.length; i++) {
      const index = _.findIndex(categories[categoryOpened].capacities, (n) => n.asrsLocationId === Number(value[i].id));
      if (index !== -1) {  // alr exists
        let capacity = payload[categoryOpened].capacities[index];
        payload[categoryOpened].capacities[index] = Object.assign(capacity, {isDeleted: false}); 
      } else {  // not in list, add new
        const capacity = [];
        for (let i = 0; i < value.length; i++) {
          capacity.push({asrsSoftzoneId: Number(softzoneValue), asrsLocationId: Number(value[i].id)});
        }
        payload[categoryOpened].capacities = _.uniqBy([...payload[categoryOpened].capacities, ...capacity], 'asrsLocationId');
      }
    }
    return payload;
  }

  const handleOnChange_text = (e) => {
    dispatch(updateCategoryRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_select = (e) => {
    let value = e.target.value;
    if (e.target.name === "productId") {
      _.remove(value, (n) => n === "Select Product / Group");
      const group = value.find((item) => Array.isArray(item));
      value = value.filter((item) => !Array.isArray(item));
      if (group && group.every((id)=>value.includes(id))) {  // if all alr selected
        value = value.filter((item) => !group.includes(item));
      } else {
        value = _.union(value, group);
      }
    }
    dispatch(updateCategoryRequest({[e.target.name] : value}));
  };

  const handleButtonClick_collapse = (collapsible, index, softzoneId) => {
    switch (collapsible) {
      case "category":
        if (categoryOpened === index) {
          setCategoryOpened(null);
        } else {
          setCategoryOpened(index);
        }
        setSoftzoneOpened(null);
        break;
      case "softzone":
        if (softzoneOpened === index) {
          setSoftzoneOpened(null);
        } else {
          dispatch(GetAllLocationBySoftzone({softzoneId}));
          setSoftzoneOpened(index);
        }
        break;
    }
  }

  const handleButtonClick_delete = () => {
    let cat = Object.assign({}, categories[categoryIndex]);
    let payload = Object.assign([], categories);
    payload[categoryIndex] = Object.assign(cat, {isDeleted: true});
    dispatch(updateRequest({categories: payload}));
    resetState();
  }

  const handleButtonClick_update = () => {
    if (validateFields()) {
      let payload = Object.assign([], categories);
      let cat = Object.assign({}, category);
      cat.productList = _.flatten(_.map(product, (group)=>_.filter(group.products, ({id})=>_.includes(cat.productId, id))));
      payload[categoryIndex] = cat;
      dispatch(updateRequest({categories: payload}));
      resetState();
    }
  }

  const handleButtonClick_addNew = () => {
    if (validateFields()) {
      const productList = _.flatten(_.map(product, (group)=>_.filter(group.products, ({id})=>_.includes(category.productId, id))));
      const payload = {...category, farmId: _.find(userDetail.farms, "isActive").id, productList, capacities: []};
      dispatch(updateRequest({categories: [...categories, payload]}));
      resetState();
    }
  }

  const handleModal_delete = (index) => {
    setCategoryIndex(index);
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleButtonClick_edit = (index) => {
    setCategoryIndex(index);
    setIsEdit(true);
    dispatch(setValues(categories[index]));
  }

  const handleButtonClick_add = () => {
    setIsAddNew(true);
  }

  const handleButtonClick_save = () => {
    Promise.all([dispatch(setDirty(false))])
    .then(() => {
      dispatch(CreateOrUpdateCapacityCategoryList())
      .then(({error}) => {
        if (error) {
          dispatch(setDirty(true));
        } else {
          handleButtonClick_exit();
        }
      })
    })
  }

  const handleButtonClick_exit = () => {
    const prop = props.location.state;
    history.push((prop && prop.prevPath) || "/admin/capacity/farm-capacity");
  }

  const validateFields = () => {
    if (!category.capacityCategoryName) {
      setErrorMsg({field: "capacityCategoryName", msg: "Please enter capacity category name"});
      return false;
    }
    if (!category.productId.length) {
      setErrorMsg({field: "productId", msg: "Please select a product"});
      return false;
    }
    if (!category.productProcess) {
      setErrorMsg({field: "productProcess", msg: "Please select a process"});
      return false;
    }
    for (let productId of category.productId) {
      let catList = _.cloneDeep(categories);
      if (isEdit) {
        catList.splice(categoryIndex);
      }
      const categoriesWithProduct = _.filter(catList, (item) => !item.isDeleted).filter(item => _.includes(item.productId, productId));
      const existed = categoriesWithProduct.filter(item => item.productProcess === category.productProcess);
      if (existed.length > 0) {
        setErrorMsg({field: "productId", msg: "Product with process already exists"});
        return false
      }
    }
    setErrorMsg(false);
    return true;
  }

  const resetState = () => {
    setCategoryIndex(null);
    setOpenDeleteModal(false);
    setIsAddNew(false);
    setIsEdit(false);
    setErrorMsg(false);
    dispatch(resetCategory());
  }

  React.useEffect(() => {
    if (categoryOpened !== null) {
      let newCageList = [];
      for (let i = 0; i < categories.length; i++) {
        if (i !== categoryOpened) newCageList = _.unionBy(newCageList, categories[i].capacities); // get all cages selected by other categories
      }
      setSelectedByOthers(newCageList);
      dispatch(GetAllSoftzone({capacityCategoryId: categories[categoryOpened].id}));
    }
  },[categoryOpened]);

  React.useEffect(() => {
    dispatch(GetAllProductProcess(category.productId));
  },[category.productId]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setDirty(true));
    dispatch(GetCapacityCategoryByFarmId());
    dispatch(GetAllProduct());
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
      dispatch(reset());
    }
  },[]);

  const renderLocation = (softzoneValue) => {
    let component = [];
    for (let i = 0; i < locationList.length; i++) {
      const value = locationList[i].bankNo + '-' + locationList[i].bayNo + '-' + locationList[i].levelNo;
      const id = locationList[i].id;
      const disabled = _.findIndex(selectedByOthers, (selected) => selected.asrsLocationId === id && !selected.isDeleted) > -1;
      const selected = _.filter(categories[categoryOpened].capacities, (capacity) => capacity.asrsLocationId === id && !capacity.isDeleted);
      component.push(
        <Grid item key={i} xs={4}>
          <Checkbox 
            id="location"
            className={classes.checkboxPadding} 
            value={id}
            checked={Boolean(selected.length && !selected.isDeleted)}
            onChange={(e) => handleButtonClick_checkbox(e, softzoneValue)}
            disabled={disabled || locationList[i].isRestrictedLocation}
          />
          <span className={disabled ? classes.disabled : undefined}>{value}</span>
        </Grid>
      );
    }
    return component;
  }

  const renderProducts = () => {
    const component = [];
    for (let i = 0; i < product.length; i++) {
      if (product[i].products && product[i].products.length) {
        component.push(
          <MenuItem key={i} className={classes.selectGroup} value={_.map(product[i].products, (el) => el.id)}>
            <Checkbox checked={_.map(product[i].products, (el) => el.id).every((id)=>category.productId.includes(id))} />
            {product[i].grouping}
          </MenuItem>
        );
        for (let products of product[i].products) {
          component.push(
            <MenuItem key={products.id} value={products.id} className={classes.selectItem}>
              <Checkbox checked={_.findIndex(category.productId, (selected) => selected === products.id) > -1} />
              {products.productName}
            </MenuItem>
          );
        }
      }
    }
    return component;
  }

  const renderForm = () => {
    return (
      <Paper key={categoryIndex} elevation={0} className={clsx(isAddNew ? classes.paper : classes.editPaper, classes.marginBottom)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              id="capacityCategoryName"
              variant="outlined" 
              inputProps={{ maxLength: 50 }}
              placeholder="Type in the category name"
              onChange={(e) => handleOnChange_text(e)}
              value={category.capacityCategoryName ? category.capacityCategoryName : ""}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              name="productId"
              multiple
              onChange={(e)=>handleOnChange_select(e)}
              value={category.productId}
              renderValue={(value) => category.productId.length === 0 ? value :_.join(_.map(_.flatten(_.map(product, (group)=>_.filter(group.products, ({id})=>_.includes(value, id)))), (item) => item.productName), ', ')}
              placeholder="Select Product / Group"
              errorMsg={errorMsg}
            >
              {renderProducts()} 
            </Select>
          </Grid>
          <Grid item xs={6}>
            <Select
              name="productProcess"
              onChange={(e)=>handleOnChange_select(e)}
              value={category.productProcess}
              placeholder="Select Process"
              errorMsg={errorMsg}
            >
              { process.map((item, index) => {
                return <MenuItem key={index} value={item}>{item}</MenuItem>;
              })} 
            </Select>
          </Grid>
          <Grid item xs={12} className={classes.paperButton}>
            <Button
              className={classes.buttonSecondary}
              onClick={()=>resetState()}
            >
              Cancel
            </Button>
            <Button
              className={classes.button}
              onClick={()=>isAddNew ? handleButtonClick_addNew() : handleButtonClick_update() }
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Paper>
    )
  }

  if (showExplanation) {
    return <BankExplanation setShowExplanation={setShowExplanation} />;
  } else {
    return (
      <React.Fragment>
        <Card 
          title = "Edit Cages Categories"
          cardActions={
            <React.Fragment>
              <Button 
                className={classes.buttonSecondary}
                onClick={() => handleButtonClick_exit()}
                disabled={isEdit || isAddNew}
              >
                Go Back
              </Button>
              <Button 
                className={classes.button}
                onClick={() => handleButtonClick_save()}
                disabled={isEdit || isAddNew}
              >
                Save
              </Button>
            </React.Fragment>
          }
        >
          { _.filter(categories, (item) => !item.isDeleted).map((item, index) => {
            if (isEdit && categoryIndex === index) {
              return renderForm()
            } else {
              return (
                <Accordion
                  key={index}
                  classes={{
                    root: classes.accordion,
                  }}
                  expanded={categoryOpened == index}
                  onChange={()=>handleButtonClick_collapse("category", index)}
                  header={
                    <div className={classes.accordionHeader}>
                      <div style={{width: "80%"}}>
                        {item.capacityCategoryName}
                        <Grid container spacing={4} className={classes.cardSubheader}>
                          <Grid item xs={8}><Typography>Product / Group: {item.productList.map((obj) => {return obj.productName}).join(", ")}</Typography></Grid>
                          <Grid item xs={4}><Typography>Process: {item.productProcess}</Typography></Grid>
                        </Grid>
                      </div>
                      <div>
                        <CustomIconButton
                          type="edit" 
                          onClick={() => handleButtonClick_edit(index)}
                        />
                        <CustomIconButton
                          type="delete" 
                          onClick={() => handleModal_delete(index)}
                        />
                      </div>
                    </div>
                  }
                >
                  { categoryOpened === index &&
                    <React.Fragment>
                      <Typography 
                        className={clsx(classes.listTitle, classes.listTitleBottom)}
                      >
                        {formatNumbers(_.filter(item.capacities, (capacity) => !capacity.isDeleted).length)} Locations Selected
                        <IconButton 
                          onClick={() => setShowExplanation(true)}
                        >
                          <img className={classes.icon} src={helper} alt="helper" />
                        </IconButton>
                      </Typography>
                      { softzoneList.map((softzone, i) => {
                        const locationSelectedByOthers = _.filter(selectedByOthers, (obj) => obj.asrsSoftzoneId === Number(softzone.softzoneID) && !obj.isDeleted);
                        const selected = _.filter(categories[categoryOpened].capacities, (capacity) => capacity.asrsSoftzoneId === Number(softzone.softzoneID) && !capacity.isDeleted);
                        return (
                          <Card
                            key={i}
                            classes={{
                              root: classes.cardPaper,
                              title: clsx(classes.cardTitle, classes.cardHeader, classes.listHeader),
                              content: classes.bankListCardContent
                            }}
                            title={
                              <React.Fragment>
                                <Checkbox 
                                  id="softzone"
                                  className={classes.checkboxPadding} 
                                  value={softzone.softzoneID}
                                  checked={locationSelectedByOthers.length + selected.length === softzone.totalLocations}
                                  onChange={(e) => handleButtonClick_checkbox(e)}
                                  disabled={locationSelectedByOthers.length === softzone.totalLocations}
                                  indeterminate={selected.length > 0 && locationSelectedByOthers.length + selected.length !== softzone.totalLocations}
                                />
                                {softzone.softzoneName}&nbsp;<span className={classes.selectedTitle}> ({selected.length} Selected)</span>
                                <div className={classes.headerButton}>
                                  <IconButton 
                                    className={softzoneOpened !== i ? classes.collapsedIcon : clsx(classes.collapsedIcon, classes.rotate)}
                                    onClick={() => handleButtonClick_collapse("softzone", i, softzone.softzoneID)}
                                  >
                                    <img className={classes.icon} src={arrow} alt="arrow" />
                                  </IconButton>
                                </div>
                              </React.Fragment>
                            }
                          >
                            { softzoneOpened === i &&
                              <Grid container spacing={4}>
                                {renderLocation(softzone.softzoneID)}
                              </Grid>
                            }
                          </Card>
                        )
                      })}
                    </React.Fragment>
                  }
                </Accordion>
              )
            }
          })}
          { isAddNew &&
            renderForm()
          }
          <Button
            className={classes.buttonSecondary}
            startIcon={<AddIcon />}
            onClick={()=>handleButtonClick_add()}
            disabled={isEdit || isAddNew}
          >
            Add New Category
          </Button>
        </Card>
        <Modal
          open={openDeleteModal}
          onClose={() => handleModal_delete(null)}
          icon={<img className={classes.icon_64} src={alert} alt="alert" />}
          title="Are you sure?"
          content="Do you really want to delete this category? This process cannot be undone."
          actions={
            <React.Fragment>
              <Button className={classes.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
              <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}
